import Image from "next/image"
import { WppCircle } from "@/components"

//Render the app footer.
const Footer = () => {

  const cssClasses = {
    titles: 'text-xxs lg:text-xs 2xl:text-sm text-gray-300 font-medium ',
    descriptiontexts: 'text-xxs xl:text-sm font-medium'
  }

  return (
    <>
      <main className='w-full h-auto flex flex-col text-white'>
        <section className='w-full h-100 md:h-40 flex flex-row flex-wrap justify-between md:justify-around items-start bg-[#0B0814] p-6' >
          <article className='w-auto h-auto flex justify-center items-center'>
            <WppCircle />
            <h3 className=' text-xxxs xl:text-xxs font-semibold ml-2'>HABLA CON NOSOTROS</h3>
          </article>
          <article className=' w-auto h-auto flex flex-col justify-start'>
            <h4 className={`${cssClasses.titles}`}>CONTACTO</h4>
            <p className={`${cssClasses.descriptiontexts}`}>administracion@marinebroker.com.ar</p>
          </article>
          <article className='w-auto h-auto flex flex-col md:flex-row'>
            <div className="flex flex-col items-start mr-6">
              <h4 className={`${cssClasses.titles}`}>OFICINAS</h4>
              <section className='w-auto h-auto flex flex-col justify-start'>
                <h6 className=' text-[0.70rem] font-semibold '>Leandro N. Alem 3463</h6>
                <p className={`${cssClasses.descriptiontexts}`}>Tel: 223 3000 182</p>
              </section>
            </div>
            <div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3142.920867532163!2d-57.53703132323914!3d-38.02562424617748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9584ddcb16be77c3%3A0x3a19f7b37d457c49!2sLeandro%20N.%20Alem%203463%2C%20B7600%20Mar%20del%20Plata%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1716244241185!5m2!1ses!2sar" className='w-80 md:w-60 h-36 md:h-28 ' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </article>
        </section>
        <section className='w-full h-16 flex flex-col md:flex-row justify-around items-center border-t border-gray-800 bg-[#0B0814] '>
          <article className='w-full md:w-1/2 h-1/2 md:h-auto flex flex-row justify-center items-center '>
            <Image
              src={'/marineBroker/logo.png'}
              width={50}
              height={100}
              alt='Logo marine brokers'
            />
            <h1 className='ml-4 text-[0.60rem] font-medium '>2024 Todos los derechos reservados.</h1>
          </article>
          <article className='w-full md:w-1/2 h-1/2 md:h-auto flex flex-row justify-center items-center  font-medium  text-xxxs'>
            <h1 className='mx-1 text-gray-300 '>Desarrollado por | </h1>
            <a target="blank" className='mx-1 underline underline-offset-4 decoration-primary ' href="https://www.linkedin.com/in/tobias-belfiore-26baa8271/">Tobias Belfiore</a>
            <p className='mx-1 text-gray-300'>y</p>
            <div className='mx-1 underline underline-offset-4 decoration-primary' href="https://www.linkedin.com/in/tobias-belfiore-26baa8271/">Fernando Mancini</div>
          </article>
        </section>
      </main>
    </>
  )
}

export default Footer