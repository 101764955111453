import Image from 'next/image'
import React from 'react'
import Link from 'next/link';

const AttendenceCard = ({ company, companyAlt, link, companyImg, data }) => {

  return (
    <button type='button' key={company} className='w-72 md:w-2/5 lg:w-80 xl:w-72 2xl:w-80 h-96 lg:h-97 2xl:h-98 gap-4 flex flex-col justify-center items-center p-6 bg-white shadow-lg shadow-slate-300 rounded-sm hover:shadow-xl hover:shadow-slate-400 transition-all duration-300'>
      <div className='flex justify-center items-center h-2/6 w-full rounded-sm shadow-md'>
        <Image
          src={companyImg}
          alt={companyAlt}
          width={20}
          height={100}
          className='w-1/2'
        />
      </div>
      <div className='w-full flex flex-col h-3/6 gap-y-3'>
        {
          data.map(element =>
            <div className='flex flex-col items-center w-full h-auto font-medium' key={element.subtitle}>
              <h3 className='text-xxs lg:text-xs 2xl:text-sm'>{element.title}</h3>
              <a className='text-sm lg:text-base 2xl:text-lg font-semibold'>{element.subtitle}</a>
            </div>
          )
        }
      </div>
      <Link target='blank' href={link} className='ring-0 bg-primary text-white transition-all duration-300 text-sm font-semibold py-3 px-6 hover:scale-105 rounded-sm'>
        Contactar compañía
      </Link>
    </button>
  )
}

export default AttendenceCard;