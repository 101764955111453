'use client'
import React from 'react';

// width = w-40 || w-52 || w-60 || w-72 || w-80 || w-96 || w-full
const CommonInput = ({ name, onChange, value, title = '', minlength = 0, maxlength = 1000, showMaxlengthCounter = false, required = false, type = 'text', testId = '', placeHolder = 'Escribe aqui...', width = 'w-80', titleColor = 'text-black' }) => {

  return (
    <label className={` ${width} flex flex-col justify-center relative`}>
      <div className={`text-xs 2xl:text-sm font-semibold flex justify-between w-full ${titleColor}`}>{title} {showMaxlengthCounter && <span>{`${inputValue.length}/${maxlength}`}</span>}</div>
      <input
        onChange={onChange}
        name={name}
        required={required}
        minLength={minlength}
        maxLength={maxlength}
        placeholder={placeHolder}
        value={value}
        data-test-id={testId}
        type={type}
        className={`w-full h-12 text-xs px-2 xl:text-sm rounded border border-gray-300 focus-within:border-gray-300 focus:border-gray-300`}
      />
    </label>
  );
}

export default CommonInput;
