'use client'
import React from 'react'
import { attendanceCards } from '@/utils'
import { AttendenceCard } from '@/components'

//This component is responsible for rendering a section that provides contact numbers for various insurers.
const Attendance = () => {

  return (
    <main className='w-full h-auto flex flex-col justify-center items-center relative gap-y-8' id='attendance'>
      <h1 className='text-center text-3xl md:text-4xl font-semibold'>En caso de emergencia, <span className=' text-primary'> actúa rápidamente.</span></h1>
      <p className='text-center text-xs md:text-sm font-medium px-8 md:w-2/3 xl:w-1/2'>Los números que te proporcionamos a continuación son esenciales para recibir asistencia inmediata. Te recomendamos tenerlos a mano y utilizarlos solo en situaciones de emergencia. </p>
      <section className='h-auto w-full lg:w-11/12 2xl:w-5/6 flex flex-col md:flex-row justify-center items-center flex-wrap md:items-start gap-8'>
        { attendanceCards.map(item => <AttendenceCard key={item.company} {...item}/>) }
      </section>
    </main>
  )
}

export default Attendance