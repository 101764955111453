import React from 'react'

const CommonButton = ({ content = 'Ingrese un contenido para el boton', testid = '', type = 'button', action = () => { }, disabled = false, primary = false }) => {
  //if primary = true ? 'El boton sera blanco con hover negro' : 'El boton sera negro con hover blanco'
  return (
    <button type={type} data-test-id={testid} disabled={disabled} onClick={action} className={`${primary ? 'ring-0 bg-primary text-white' : 'bg-white text-black shadow shadow-gray-400'} transition-all duration-300 text-sm font-semibold py-3 px-6 hover:scale-105 rounded-sm`}>
      {content}
    </button>
  )
}

export default CommonButton