'use client'
import React, { useActionState, useEffect } from 'react'
import { CommonButton, CommonInput } from '@/components'
import CommonTextArea from '@/components/common/CommonTextArea';
import { contactInputs } from '@/utils'
import { toast } from 'sonner';
import { contact } from '@/app/client/formClientActions';

const textArea = {
  title: 'Mensaje',
  titleColor: 'text-white',
  name: 'message',
  width : 'w-full',
  type: "text",
  placeHolder: 'Escriba aqui...',
  minlength: '10',
  maxlength: '500',
  showMaxlengthCounter : true,
  required: true
}

/**
 * @function Contact - This component is responsible for displaying a contact section with a form and email sending functionality using the EmailJS library.
 * The function sends the data entered by the user to Marine Broker's email.
 * It checks if the user has sent more than 3 emails per day using cookies, preventing spam.
 * The function provides feedback via toast notifications. 
 */
const Contact = () => {

  const [data, formAction, pending] = useActionState(contact, { status: false, message: '' })

  useEffect(() => {
    if (data.status) {
      let { status, message } = data;
      switch (status) {
        case 200:
          toast.success(message)
          break;
        case 401:
          toast.warning(message)
          break;
        default: toast.error(message)
          break;
      }
    }
  }, [data])

  return (
    <main id='contact' className='h-auto w-full flex flex-col md:flex-row md: justify-around py-20 gap-8 md:p-16 xl:p-40 bg-gradient-to-r from-black to-[#0B0814] relative'>
      <span className=' bg-primary h-48 md:h-60 w-72 md:w-96 absolute md:bottom-0 md:left-0 blur-[200px]'></span>
      <section className='w-full md:w-1/2 xl:w-1/3 h-min flex flex-col justify-around items-start p-4 md:p-0 md:pr-20 gap-y-8 '>
        <h1 className='text-4xl 2xl:text-5xl tracking-wide font-semibold text-white'>Contactanos</h1>
        <h4 className=' text-xs md:text-sm font-light text-start text-gray-100'>Para más información sobre nuestros servicios o para programar una consulta, por favor contáctenos. Estamos aquí para servirle y guiarle hacia la mejor protección del mercado.<br /> Gracias por considerar <span className='text-primary font-bold text-sm md:text-lg'>Marine Broker</span> para sus necesidades de seguros.</h4>
      </section>
      <section className='w-full md:w-102 xl:w-104 h-min flex flex-col justify-around items-start px-8 gap-y-4'>
        <h2 className='font-semibold text-2xl xl:text-3xl text-white'>Envianos un <span className='text-primary'> mail</span></h2>
        <form action={formAction} className='w-full h-2/3 flex flex-col md:flex-row md:flex-wrap justify-between md:items-center gap-y-3'>
          {
            contactInputs.map(item => <CommonInput key={item.name} {...item} width='md:w-[45%] xl:w-2/5' />)
          }
          <div className='w-full flex flex-col'>
            <CommonTextArea {...textArea} />
          </div>
          <CommonButton type='submit' content={pending ? 'Enviando...' : 'Enviar mensaje'} primary />
        </form>
      </section>
    </main>
  )
}

export default Contact